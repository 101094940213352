import { RouteRecordRaw } from "vue-router";

export default [
  {
    path: "/",
    component: () => import(/* webpackChunkName: "CASINO8" */ "./index.vue"),
    children: [
      {
        path: "/APP",
        component: () =>
          import(/* webpackChunkName: "CASINO8" */ "./PAGES/APP/index.vue"),
        meta: {
          title: "CasinoCore.APP_DOWNLOAD",
        },
      },
      {
        path: "/login",
        component: () =>
          import(/* webpackChunkName: "CASINO8" */ "./PAGES/login/index.vue"),
        meta: {
          title: "CasinoCore.login",
        },
      },
      {
        path: "/register",
        component: () =>
          import(
            /* webpackChunkName: "CASINO8" */ "./PAGES/register/index.vue"
          ),
        meta: {
          title: "CasinoCore.register",
        },
      },
      {
        path: "/smsVerify/:account/:phone?",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "CASINO8" */ "./PAGES/register/smsVerify/index.vue"
          ),
        meta: {
          title: "CasinoCore.smsVerifyStatus",
        },
      },
      {
        path: "/",
        name: "C5",
        component: () =>
          import(/* webpackChunkName: "CASINO8" */ "./PAGES/index.vue"),
        children: [
          {
            /** HOMEPAGE */
            path: "",
            alias: "/home",
            component: () =>
              import(
                /* webpackChunkName: "CASINO8" */ "./PAGES/HOME/index.vue"
              ),
            meta: {
              title: "CasinoCore.HOME",
            },
          },
          {
            path: "sport",
            component: () =>
              import(
                /* webpackChunkName: "CASINO8" */ "./PAGES/GAMES/sport.vue"
              ),
            meta: {
              title: "CasinoCore.SPORT",
            },
          },
          {
            path: "casino",
            component: () =>
              import(
                /* webpackChunkName: "CASINO8" */ "./PAGES/GAMES/casino.vue"
              ),
            meta: {
              title: "CasinoCore.CASINO",
            },
          },
          {
            path: "chess",
            component: () =>
              import(
                /* webpackChunkName: "CASINO8" */ "./PAGES/GAMES/chess.vue"
              ),
            meta: {
              title: "CasinoCore.CHESS",
            },
          },
          {
            path: "gaming",
            component: () =>
              import(
                /* webpackChunkName: "CASINO8" */ "./PAGES/GAMES/gaming.vue"
              ),
            meta: {
              title: "CasinoCore.ESPORT",
            },
          },
          {
            path: "lottery",
            component: () =>
              import(
                /* webpackChunkName: "CASINO8" */ "./PAGES/GAMES/lottery.vue"
              ),
            meta: {
              title: "CasinoCore.LOTTERY",
            },
          },
          {
            path: "egaming",
            component: () =>
              import(
                /* webpackChunkName: "CASINO8" */ "./PAGES/GAMES/egame.vue"
              ),
            meta: {
              title: "CasinoCore.EGAMING",
            },
          },
          // {
          //   path: "fishing",
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "CASINO8" */ "./PAGES/GAMES/fishing.vue"
          //     ),
          //   meta: {
          //     title: "CasinoCore.FISHING",
          //   },
          // },
          {
            path: "news/:type?/:block?/:id?",
            alias: "news",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "CASINO8" */ "./PAGES/news/index.vue"
              ),
          },
          {
            path: "promotion/:type?/:block?",
            alias: "promotion",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "CASINO8" */ "./PAGES/promotion/index.vue"
              ),
          },
          {
            path: "promotion/:type/:block/:id",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "CASINO8" */ "./PAGES/promotion/info/index.vue"
              ),
            meta: {
              title: "CasinoCore.PROMOTIONS",
            },
          },
          {
            path: "announcement/:type?/:block?",
            alias: "announcement",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "CASINO9" */ "./PAGES/announcement/index.vue"
              ),
            meta: {
              title: "CasinoCore.announcement",
            },
          },
          {
            path: "announcement/:type/:block/:id",
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "CASINO9" */ "./PAGES/announcement/info/index.vue"
              ),
            meta: {
              title: "CasinoCore.announcement",
            },
          },
          {
            path: "help/:block?",
            component: () =>
              import(
                /* webpackChunkName: "CASINO8" */ "./PAGES/help/index.vue"
              ),
            props: true,
            meta: {
              title: "CasinoCore.helpCenter",
            },
          },
          {
            path: "/AD/:type/:block/:id",
            props: true,
            component: () =>
              import(/* webpackChunkName: "CASINO8" */ "./PAGES/AD/index.vue"),
          },
          {
            path: "myCenter",
            component: () =>
              import(
                /* webpackChunkName: "CASINO8" */ "./PAGES/myCenter/index.vue"
              ),
            children: [
              {
                alias: "",
                path: "userInfo",
                component: () =>
                  import(
                    /* webpackChunkName: "CASINO8" */ "./PAGES/myCenter/userInfo/index.vue"
                  ),
                meta: {
                  title: "CasinoCore.memberInfo",
                },
              },
              {
                path: "bank",
                component: () =>
                  import(
                    /* webpackChunkName: "CASINO8" */ "./PAGES/myCenter/bank/index.vue"
                  ),
                meta: {
                  title: "CasinoCore.bank",
                },
              },
              {
                path: "deposit",
                component: () =>
                  import(
                    /* webpackChunkName: "CASINO8" */ "./PAGES/myCenter/deposit/index.vue"
                  ),
                meta: {
                  title: "CasinoCore.stored",
                },
              },
              {
                path: "depositResult/:payment?",
                component: () =>
                  import(
                    /* webpackChunkName: "CASINO8" */ "./PAGES/myCenter/depositResult/index.vue"
                  ),
                props: true,
                meta: {
                  title: "CasinoCore.stored",
                },
              },
              {
                path: "depositRecord",
                component: () =>
                  import(
                    /* webpackChunkName: "CASINO8" */ "./PAGES/myCenter/depositRecord/index.vue"
                  ),
                meta: {
                  title: "CasinoCore.record-stored",
                },
              },
              {
                path: "promotionRecord",
                component: () =>
                  import(
                    /* webpackChunkName: "CASINO9" */ "./PAGES/myCenter/promotionRecord/index.vue"
                  ),
                meta: {
                  title: "CasinoCore.record-promotions",
                },
              },
              {
                path: "auction",
                component: () =>
                  import(
                    /* webpackChunkName: "CASINO8" */ "./PAGES/myCenter/auction/index.vue"
                  ),
                meta: {
                  title: "CasinoCore.auction",
                },
              },
              {
                path: "auctionResult/:payment?",
                component: () =>
                  import(
                    /* webpackChunkName: "CASINO8" */ "./PAGES/myCenter/auctionResult/index.vue"
                  ),
                props: true,
                meta: {
                  title: "CasinoCore.auction",
                },
              },
              {
                path: "auctionRecord",
                component: () =>
                  import(
                    /* webpackChunkName: "CASINO8" */ "./PAGES/myCenter/auctionRecord/index.vue"
                  ),
                meta: {
                  title: "CasinoCore.record-auction",
                },
              },
              {
                path: "transfer",
                component: () =>
                  import(
                    /* webpackChunkName: "CASINO8" */ "./PAGES/myCenter/transfer/index.vue"
                  ),
                meta: {
                  title: "CasinoCore.transfer",
                },
              },
              {
                path: "betRecord",
                component: () =>
                  import(
                    /* webpackChunkName: "CASINO8" */ "./PAGES/myCenter/betRecord/index.vue"
                  ),
                meta: {
                  title: "CasinoCore.gameDetail",
                },
              },
              {
                path: "betLedger",
                component: () =>
                  import(
                    /* webpackChunkName: "CASINO8" */ "./PAGES/myCenter/betLedger/index.vue"
                  ),
                meta: {
                  title: "CasinoFrontGames.betLedger",
                },
              },
              {
                path: "reward",
                component: () =>
                  import(
                    /* webpackChunkName: "CASINO8" */ "./PAGES/myCenter/reward/index.vue"
                  ),
                meta: {
                  title: "CasinoCore.rewardGrantRecord",
                },
              },
              {
                path: "bonusRecord",
                component: () =>
                  import(
                    /* webpackChunkName: "CASINO8" */ "./PAGES/myCenter/bonusRecord/index.vue"
                  ),
                meta: {
                  title: "CasinoCore.record-bonus",
                },
              },
              {
                path: "redEnvelopeRecord",
                component: () =>
                  import(
                    /* webpackChunkName: "CASINO7" */ "./PAGES/myCenter/redEnvelope/index.vue"
                  ),
                meta: {
                  title: "CasinoCore.record-redEnvelope",
                },
              },
            ],
          },
          {
            path: "service",
            component: () =>
              import(
                /* webpackChunkName: "CASINO8" */ "./PAGES/service/index.vue"
              ),
            meta: {
              title: "CasinoCore.customerService",
            },
          },
        ],
      },
    ],
  },
  //      {
  //        path: "/myCenter/promo/list",
  //        component: () =>
  //          import(
  //            /* webpackChunkName: "CASINO8" */ "./PAGES/myCenter/promoList/index.vue"
  //          ),
  //      },
  //      {
  //        path: "service/:type/:block/:id",
  //        props: true,
  //        component: () =>
  //          import(
  //            /* webpackChunkName: "CASINO8" */ "./PAGES/service/info/index.vue"
  //          ),
  //        meta: {
  //          title: "CasinoCore.customerService",
  //        },
  //      },
  //      {
  //        path: "support",
  //        component: () =>
  //          import(/* webpackChunkName: "CASINO8" */ "./PAGES/null.vue"),
  //      },
  //      {
  //        path: "about",
  //        component: () =>
  //          import(/* webpackChunkName: "CASINO8" */ "./PAGES/about/index.vue"),
  //        meta: {
  //          title: "CasinoCore.aboutUs",
  //        },
  //      },
] as RouteRecordRaw[];
