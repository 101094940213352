<template>
  <router-view />
</template>

<script lang='ts'>
import { computed, watch } from "@vue/runtime-core";
import useDevice from "./API/useDevice.ts";
import socket from "./library/socket.ts";
import { useStore } from "vuex";
/**
 * 基底層
 */
export default {
  name: "APP",
  setup() {
    useDevice();
    const { initWebSocket } = socket();
    const $store = useStore();
    const isLogined = computed(() => $store.getters["account/isLogined"]);

    watch(
      isLogined,
      (v: boolean) => {
        if (v) initWebSocket();
      },
      { immediate: true },
    );
  },
};
</script>

<style lang="scss" src="./assets/GLOB_STYLE.scss"></style>